<template>
  <div class="vs-row w-full">
    <template v-if="$route.params.lang === 'ar'">
      <div class="vs-col bg-auto bg-bottom bg-no-repeat fixed rightDiv" :class="{'right-1/2 w-1/2 h-full':window.width >= 768, 'top-1/2 w-full h-3/5':window.width < 768}">
        <div class="xl:mt-10 md:mt-10 mt-8 md:mr-12 mr-8">
          <p class="md:text-2xl sm:text-xl" style="color: #00DCDC;">Be Part Of The Adventure</p>
          <p class="md:pt-3 sm:pt-1 md:text-base sm:text-sm">Please, insert your phone number</p>
          <div class="md:mt-6 sm:mt-2 mt-4 flex">
            <input type="text" class="phoneInput" placeholder="Full Name">
          </div>
          <div class="md:mt-6 sm:mt-2 mt-4 flex">
            <vs-dropdown disabled="true">
              <vs-button class="btn-drop" type="border" :icon-after="true" icon="arrow_drop_down">+966</vs-button>
              <vs-dropdown-menu>
              </vs-dropdown-menu>
            </vs-dropdown>
            <input type="text" class="mr-3 phoneInput" placeholder="Phone Number">
          </div>
          <div class="md:mt-6 sm:mt-2 mt-4 flex">
            <input type="email" class="phoneInput" placeholder="E-Mail">
          </div>
          <div class="md:mt-8 sm:mt-4 mt-5 mb-2">
            <vs-button class="ml-2 mb-0 rightButtons" color="#F89416" type="filled">Sign up</vs-button>
          </div>
          <span class="md:mt-8 sm:mt-4 mt-5 font-medium text-gray-500">Already have account?</span>
          <span><span @click="gotoPage('login')" class="cursor-pointer text-blue underline decoration-1 ml-1">Login</span></span>
        </div>
      </div>
      <div class="vs-col fixed bg-center backgroundImage" :class="{'w-1/2 h-full bg-cover':window.width >= 768, 'w-full h-1/2':window.width < 768}">
        <div class="transbox text-white" :class="{'w-1/2 h-full':window.width >= 768, 'w-full h-1/2':window.width < 768}">
          <div class="text-center md:mt-20 mt-8">
            <img class="xl:w-1/4 lg:w-1/4 md:w-1/4 w-32 m-auto" src="@/assets/images/logo.png" alt="">
            <p class="md:text-xl text-lg mt-5 md:w-2/3 w-3/4 m-auto">Lorem ipsum dolor sit amet, consectetur sadipscing elitr, sed</p>
            <div class="mt-12">
              <vs-button @click="gotoPage('login')" class="mx-2 font-black leftButtons mb-4" type="border">Login</vs-button>
              <vs-button @click="gotoPage('register')" class="mx-2 font-black leftButtons" type="border">Sign up</vs-button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="vs-col fixed bg-center backgroundImage" :class="{'w-1/2 h-full bg-cover':window.width >= 768, 'w-full h-1/2':window.width < 768}">
        <div class="transbox text-white" :class="{'w-1/2 h-full':window.width >= 768, 'w-full h-1/2':window.width < 768}">
          <div class="text-center md:mt-20 mt-8">
            <img class="xl:w-1/4 lg:w-1/4 md:w-1/4 w-32 m-auto" src="@/assets/images/logo.png" alt="">
            <p class="md:text-xl text-lg mt-5 md:w-2/3 w-3/4 m-auto">Lorem ipsum dolor sit amet, consectetur sadipscing elitr, sed</p>
            <div class="mt-12">
              <vs-button @click="gotoPage('login')" class="mx-2 font-black leftButtons mb-4" type="border">Login</vs-button>
              <vs-button @click="gotoPage('register')" class="mx-2 font-black leftButtons" type="border">Sign up</vs-button>
            </div>
          </div>
        </div>
      </div>
      <div class="vs-col bg-auto bg-bottom bg-no-repeat fixed rightDiv" :class="{'left-1/2 w-1/2 h-full':window.width >= 768, 'top-1/2 w-full h-3/5':window.width < 768}">
        <div class="xl:mt-10 md:mt-10 mt-8 md:ml-12 ml-8">
          <p class="md:text-2xl sm:text-xl" style="color: #00DCDC;">Be Part Of The Adventure</p>
          <p class="md:pt-3 sm:pt-1 md:text-base sm:text-sm">Please, insert your phone number</p>
          <div class="md:mt-6 sm:mt-2 mt-4 flex">
            <input type="text" class="phoneInput" placeholder="Full Name">
          </div>
          <div class="md:mt-6 sm:mt-2 mt-4 flex">
            <vs-dropdown disabled="true">
              <vs-button class="btn-drop" type="border" :icon-after="true" icon="arrow_drop_down">+966</vs-button>
              <vs-dropdown-menu>
              </vs-dropdown-menu>
            </vs-dropdown>
            <input type="text" class="ml-3 passwordInput" placeholder="Phone Number">
          </div>
          <div class="md:mt-6 sm:mt-2 mt-4 flex">
            <input type="email" class="phoneInput" placeholder="E-Mail">
          </div>
          <div class="md:mt-8 sm:mt-4 mt-5 mb-2">
            <vs-button class="mr-2 mb-0 rightButtons" color="#F89416" type="filled">Sign up</vs-button>
          </div>
          <span class="md:mt-8 sm:mt-4 mt-5 font-medium text-gray-500">Already have account?</span>
          <span><span @click="gotoPage('login')" class="cursor-pointer text-blue underline decoration-1 ml-1">Login</span></span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
// import axios from '../axios.js'

export default {
  name: 'SignUp',
  data () {
    return {
      window: {
        width: 0
      },
    }
  },
  methods: {
    gotoPage (name) {
      this.$router.push({name}).catch(() => {})
    },
    handleResize() {
      this.window.width = window.innerWidth;
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
}
</script>

<style scoped>
.backgroundImage {
  background-image: url("../assets/images/footer.jpg");
}
.transbox {
  position: fixed;
  /* width: 50%;
  height: 100%; */
  background-color: #05d8dc80;
  /* background-image: linear-gradient(to bottom, #05d8dc80,rgba(89, 131, 127, 0.596)); */
  opacity: 0.85;
}
.leftButtons {
  border: 1px solid white;
  color: white; 
  padding: 5px 50px;
  border-radius: 5px !important;
}
.leftButtons:hover, .leftButtons:focus {
  color: #00DCDC !important;
  background: white !important; 
}
.rightButtons {
  padding: 5px 37px;
  border-radius: 2px !important;
}
.btn-drop {
  border: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 0px;
  color: #00DCDC !important;
  padding: 10px 0px !important;
}
.phoneInput {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 0px;
  padding: 10px 2px;
  width: 50%;
}
.passwordInput {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 0px;
  padding: 10px 2px;
  width: 42%;
}
.rightDiv {
  background-image: url(../assets/images/joinus.svg);
  background-size: 450px;
}
@media (max-width: 770px) {
  .phoneInput {
    width: 70%;
  }
  .passwordInput {
    width: 57%;
  }
  .rightButtons {
    padding: 5px 25px;
  }
}
@media (max-width: 1280px) {
  .rightDiv {
    background-size: 400px;
  }
}
@media (max-width: 775px) {
  .rightButtons {
    margin: 5px 10px 0px 0px;
  }
  .rightDiv {
    background-size: 350px !important;
  }
}
@media (max-width: 640px) {
  .rightDiv {
    background-size: 250px !important;
  }
  .rightButtons {
    padding: 5px 15px !important;
  }
}
</style>